import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import store from "./store";
import VueSocketIO from 'vue-socket.io'
import _axios from "axios"
import { cacheAdapterEnhancer } from 'axios-extensions';
import "../node_modules/@mdi/font/css/materialdesignicons.min.css";
import $buefy from "buefy";
import "buefy/dist/buefy.css";
Vue.use($buefy);


//const options = { path: '/my-app/' }; //Options object to pass into SocketIO
Vue.use(new VueSocketIO({
  debug: true,
  connection: process.env.VUE_APP_SOCKET,
  /*vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_'
  },*/
  //options: { path: "/my-app/" } //Optional options
}))


const axios = _axios.create({
  baseURL: `${process.env.VUE_APP_URI}`,
  headers: { 'Cache-Control': 'no-cache' },
  // disable the default cache and set the cache flag
  adapter: cacheAdapterEnhancer(_axios.defaults.adapter, { enabledByDefault: false, cacheFlag: 'useCache' })
});

axios.interceptors.request.use(req => {
  // `req` is the Axios request config, so you can modify
  // the `headers`.
  if (req.url.includes("viacep")) {
    req.headers = {};
  } else {
    const token = store.getters.token;
    req.headers.authorization = `Bearer ${token}`;
  }

  return req;
});
axios.interceptors.response.use(
  (response) => {
    //const now = Date.now();
    //console.info(`Api Call ${response.config.url} took ${now - response.config.config.start}ms`);
    //Snackbar.open(`positioned bottom-right with a green 'OK' button`)
    return response;
  },
  (error) => {
    return Promise.reject(error)
  },
);
Vue.prototype.$http = axios;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
