<template>
  <div>
    <!-- open terminal -->
    <section v-if="!setting.opened" class="has-background-info">
      <div class="columns centerall">
        <div class="column is-4">
          <div class="box">
            <div class="
                is-flex is-justify-content-center is-align-items-center
                mb-4
              ">
              <p class="image is-64x64">
                <img src="@/assets/logoMaxDoctor.png" />
              </p>
              <div class="has-text-centered ml-4">
                <p class="title">Max Doctor TV</p>
              </div>
            </div>

            <p v-show="error" class="
                subtitle
                p-2
                has-text-danger-dark
                has-background-danger-light
                has-text-centered
              ">
              Terminal não Encontrado!
            </p>
            <div class="columns is-vcentered is-centered pt-4">
              <b-button @click="setting.opened = true" :disabled="error" :loading="loading" class="open-terminal">Abrir
                Terminal</b-button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div v-if="setting.opened" class="terminal">

      <figure class="image is-16by9">
        <video :src="this.terminal.media" autoplay muted loop class="has-ratio"></video>
      </figure>

      <!-- <div class="columns">
        <div class="column is-12">
          <div class="is-16by9">
            <video :src="this.terminal.media" autoplay muted loop></video>
          </div>
        </div>
      </div> -->

      <div class="footer">
        <div class="
            pl-4
            pr-4
            is-flex is-justify-content-space-between is-align-items-center
          ">
          <span>{{ terminal.name }}</span>
          <span>DrTV - Media indoor para clínicas</span>
          <span>
            <!-- Only renders when the device is offline -->
            <b-icon v-show="!setting.isConnected" icon="web-off" type="is-danger" class="mr-2"></b-icon>

            <!-- Only renders when the device is online -->
            <b-icon v-show="setting.isConnected" icon="web-check" type="is-success" class="mr-2"></b-icon>

            <!-- disconnected -->
            <b-icon v-show="!setting.isConnected" icon="broadcast-off" type="is-danger" class="mr-2"></b-icon>
            <!-- connected -->
            <b-icon v-show="setting.isConnected" icon="broadcast" type="is-success" class="mr-2"></b-icon>
            <span class="total-user">
              {{ totalDoctors }}
              <b-icon v-show="setting.isConnected" icon="account-tie-voice" class="mr-2"></b-icon>
            </span>
            <a @click.prevent="load"><b-icon v-show="true" icon="reload"></b-icon></a>
            <span> {{ setting.date | dateFormat }}</span>
          </span>
        </div>
      </div>
    </div>
    <audio id="call">
      <source src="./../assets/call.mp3" type=audio/mpeg>
    </audio>
  </div>
</template>
<script>
export default {
  name: "Terminal",
  data() {
    return {
      terminal: {},
      Utterance: "",
      calls: [],
      speaking: false,
      loading: false,
      error: false,
      setting: {
        opened: false,
        date: "",
        isConnected: false,
        isOnLine: navigator.isOnLine,
        isSubscribe: false,
      },
    };
  },
  sockets: {
    connect() {
      //Fired when the socket connects.
      //this.broadcast(); //SE SETAR, QUANDO VOLTA A CONEXÃO, CHAMA VÁRIAS VEZES
      this.setting.isConnected = true;
    },

    disconnect() {
      this.setting.isSubscribe = false;
      this.setting.isConnected = false;
    },
  },
  computed: {
    terminalKey() {
      return this.$route.params.key;
    },
    totalDoctors: function () {
      if (!this.terminal.office) return;
      return Object.keys(this.terminal.office).length;
    },
  },
  filters: {
    dateFormat: function (v) {
      if (!v) return;
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return new Intl.DateTimeFormat("pt-Br", options).format(v);
    },
  },
  mounted() {
    const self = this;

    self.Utterance = new SpeechSynthesisUtterance();
    const voices = window.speechSynthesis.getVoices();
    self.Utterance.voice = voices[0]; // Note: some voices don't support altering params
    self.Utterance.voiceURI = "native";
    self.Utterance.volume = 1; // 0 to 1
    self.Utterance.rate = 1; // 0.1 to 10
    self.Utterance.pitch = 0; //0 to 2
    //msg.text = 'Hello World';
    //msg.lang = 'pt-br';

    setInterval(function () {
      //a cada 1seg, tenta fazer a chamada
      self.call();
      //ajusta a hora
      self.setting.date = new Date();
    }, 1000);
  },
  created() {
    this.loading = true;
    this.$http
      .get(`terminal/admin/open/${this.terminalKey}`)
      .then((response) => {
        this.loading = false;
        if (!response.data) {
          this.error = true;
        } else {
          this.terminal = Object.assign({}, this.terminal, response.data);
          this.broadcast();
        }
      });
  },
  methods: {
    //faz a chamada no terminal
    //https://wicg.github.io/speech-api/#examples-recognition
    call: function () {
      const self = this;

      if (self.speaking || self.calls.length == 0) return;

      self.$buefy.snackbar.open({
        duration: 180000,
        message: `${self.calls[0].text}. <span class="room">Sala ${self.calls[0].room}</span>`,
        position: "is-bottom-left",
        queue: false,
        actionText: "",
      });

      self.speaking = true;
      document.getElementById("call").play();
      self.Utterance.text = `${self.calls[0].text}. Sala ${self.calls[0].room}`;
      speechSynthesis.speak(self.Utterance);

      self.Utterance.onend = function (event) {
        self.speaking = false;
        self.calls.shift();
        console.log(
          `call: ${self.Utterance.text} - Finished in ${event.elapsedTime}s`
        );
        // self.$http.put(`queue/call/${queueId}`).then(() => {
        //   console.log(
        //     `call: ${self.Utterance.text} - Finished in ${event.elapsedTime}s`
        //   );
        // });
      };
    },
    broadcast() {
      if (!this.terminal) return;
      if (this.setting.isSubscribe) return;
      this.sockets.subscribe(this.terminalKey, (data) => {
        this.calls.push(data);
      });
      this.setting.isSubscribe = true;
    },
  },
};
</script>
<style>
  html {
    overflow: hidden !important;
  }

  .centerall {
    height: 100vh;
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .snackbar {
    opacity: 0.7 !important;
  }

  .snackbar .text {
    font-size: 2.5rem !important;
  }

  .snackbar .room {
    color: yellow;
  }
</style>
<style scoped>
  .footer {
    background-color: hsl(0, 0%, 4%);
    color: hsl(171, 100%, 96%);
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0.2rem;
  }

  .offline {
    width: 100%;
    height: 100%;
    background-color: #fc9842;
    background-image: linear-gradient(315deg, #fc9842 0%, #fe5f75 74%);
  }

  .online {
    width: 100%;
    height: 100%;
    background-color: #00b712;
    background-image: linear-gradient(315deg, #00b712 0%, #9bca15 74%);
  }
</style>