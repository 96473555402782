// store/index.js

import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from 'vuex-persist';

Vue.use(Vuex);

const state = {
    token: "",
    terminal: {},
}


// mutations are operations that actually mutate the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
const mutations = {

    token(state, value = "") {
        state.token = value;
    },
    terminal(state, value) {
        state.terminal = value;
    },
}

const getters = {
    token: state => state.token,
    terminal: state => state.terminal,
}

const vuexLocal = new VuexPersist({
    storage: window.localStorage,
    reducer: state => ({
        token: state.token,
        terminal: state.terminal
    })
})


export default new Vuex.Store({
    state,
    getters,
    mutations,
    //actions
    plugins: [vuexLocal.plugin]
});