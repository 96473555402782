import Vue from "vue"
import VueRouter from "vue-router"

import Index from "./../views/Index.vue";

Vue.use(VueRouter)

const routes = [
  //auth
  {
    path: "/",
    component: () => import("@/views/auth/Login"),
  },
  {
    path: "/recover_password",
    component: () => import("@/views/auth/RecoverPassword")
  },
  {
    path: "/reset_password/:tokenPassword",
    component: () => import("@/views/auth/ResetPassword")
  },

  //terminais
  {
    path: "/terminals",
    component: () => import("@/views/terminals/Index")
  },
  {
    path: "/:key",
    name: "Index",
    component: Index
  }
];

const router = new VueRouter({
  routes
})

export default router
